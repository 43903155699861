import React from 'react'
import Masonry from 'react-masonry-css'
import { Link } from "gatsby"

import styles from './specialties.module.css'

const Sp = ({items}) => {
	let breakpointCols={default: 2, 800: 1};
	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrappers}>
					<h1>Spesialization of Reviving Wholeness </h1>
					<Masonry
					  breakpointCols={breakpointCols}
					  className={styles.items}
					  columnClassName={styles.itemWrapper}
					>
						{
							items.map((item, pindex)=>{
								return(
									<div className={`${styles.itemWrapper}`} key={pindex}>
										<h3>
											<Link to={`/specialties/${item.slug}`}>
												{item.name}
											</Link>
										</h3>
										<ul className={styles.catItemsWrapper}>
											{
												item.specialties.map((i,index)=>{
													return(
														<li key={index}>
															<Link to={`/specialties/${item.slug}/${i.slug}`} className={styles.catName}>
																{i.name}
															</Link>
														</li>
													)
												})
											}
										</ul>
									</div>
								)
							})
						}
					</Masonry>
				</div>
			</div>
		</>
	)
}

export default Sp;
