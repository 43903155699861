import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import Specialties from '../components/specialties/index' 

const AboutPage = () => {
	return(
		<Layout>
		  <SEO title="Our specialties" />
		  <Specialties/>
		</Layout>
	)
}

export default AboutPage
