import React from 'react'
import {useStaticQuery, Link} from "gatsby"

import Specialty from './specialty.js'

const SP = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiSpecialtyCategories(sort: {order: ASC, fields: created_at}) {
			    edges {
			      node {
			        name
			        excerpt
			        slug
			        specialties {
			        	slug
			        	name
						feature_image {
							childImageSharp {
								fluid {
									src
								}
							}
						}
			        }
			      }
			    }
			}
		}
	`)
	let s = [];
	query.allStrapiSpecialtyCategories.edges.forEach(({node})=>{
		s.push(node);
	})
	return (
		<Specialty items={s}/>
	)
}

export default SP;
